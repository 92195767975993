import { __awaiter, __generator } from "tslib";
import superagent from 'superagent';
import { readCookie, removeCookie, writeCookie } from '../utils/cookies';
export var ACCESS_TOKEN = 'AccessTokenCMS';
export var BASE_DOCUMENT = 'https://slotletselschade.staging.appec.nl/api/v1/documents/';
export var BASE_URL = 'https://slotletselschade.staging.appec.nl/api/v1/admin/';
// export const BASE_DOCUMENT = 'http://localhost:3000/api/v1/documents/';
// export const BASE_URL = 'http://localhost:3000/api/v1/admin/';
export var EndPoints;
(function (EndPoints) {
    EndPoints["login"] = "login/";
    EndPoints["users"] = "users/";
    EndPoints["usersCreate"] = "users/create/";
    EndPoints["usersDelete"] = "users/delete/";
    EndPoints["feed"] = "feed/";
    EndPoints["feedParse"] = "feed/parse/";
    EndPoints["faq"] = "faq/";
    EndPoints["faqCreate"] = "faq/create/";
    EndPoints["faqUpdate"] = "faq/update/";
    EndPoints["faqPosition"] = "faq/position/";
    EndPoints["faqDelete"] = "faq/delete/";
    EndPoints["config"] = "config/";
    EndPoints["configUpdate"] = "config/update/";
    EndPoints["push"] = "push/";
    EndPoints["customers"] = "customers/";
    EndPoints["customersCreate"] = "customers/create/";
    EndPoints["customersUpdate"] = "customers/update/";
    EndPoints["customersDelete"] = "customers/delete/";
    EndPoints["customersDetails"] = "customers/details/";
    EndPoints["upload"] = "upload/";
    EndPoints["documents"] = "documents/";
    EndPoints["documentsUpdate"] = "documents/update/";
    EndPoints["documentsDelete"] = "documents/delete/";
})(EndPoints || (EndPoints = {}));
export var UploadType;
(function (UploadType) {
    UploadType["pdf"] = "pdf";
})(UploadType || (UploadType = {}));
export function checkIfLogged() {
    return readCookie(ACCESS_TOKEN) !== null;
}
export function setAuthToken(token) {
    writeCookie(ACCESS_TOKEN, token, 365);
}
export function getAuthToken() {
    return readCookie(ACCESS_TOKEN) || '';
}
export function getAuthHeader() {
    return {
        'Authorization': 'Bearer ' + getAuthToken(),
        'Language': 'nl',
    };
}
export function post(endPoint, data) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, superagent
                    .post(BASE_URL + endPoint)
                    .set({ 'Authorization': 'Bearer ' + getAuthToken(), 'Language': 'nl', 'Content-Type': 'application/json' })
                    .send(data)
                    .accept('application/json')
                    .then(function (result) {
                    /*
                    if (result.body.data && result.body.data.error) {
                        if (result.body.data.error.code === 401) {
                            removeCookie(ACCESS_TOKEN);
                            (window as any).location = window.location.pathname;
                        } else {
                            throw result.body.data.error.message;
                        }
                    }
                    */
                    return result.body.data;
                }, function (error) {
                    var _a, _b, _c, _d, _e;
                    var body = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.body;
                    if (((_c = (_b = body === null || body === void 0 ? void 0 : body.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.code) === 401) {
                        removeCookie(ACCESS_TOKEN);
                        window.location = window.location.pathname;
                    }
                    else {
                        throw (_e = (_d = body === null || body === void 0 ? void 0 : body.data) === null || _d === void 0 ? void 0 : _d.error) === null || _e === void 0 ? void 0 : _e.message;
                    }
                })];
        });
    });
}
export function get(endPoint, data) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, superagent
                    .get(BASE_URL + endPoint)
                    .set({ 'Authorization': 'Bearer ' + getAuthToken(), 'Language': 'nl', 'Content-Type': 'application/json' })
                    .query(data)
                    .accept('application/json')
                    .then(function (result) {
                    /*
                    if (result.body.data && result.body.data.error) {
                        if (result.body.data.error.code === 401) {
                            removeCookie(ACCESS_TOKEN);
                            (window as any).location = window.location.pathname;
                        } else {
                            throw result.body.data.error.message;
                        }
                    }
                    */
                    return result.body.data;
                }, function (error) {
                    var _a, _b, _c, _d, _e;
                    var body = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.body;
                    if (((_c = (_b = body === null || body === void 0 ? void 0 : body.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.code) === 401) {
                        removeCookie(ACCESS_TOKEN);
                        window.location = window.location.pathname;
                    }
                    else {
                        throw (_e = (_d = body === null || body === void 0 ? void 0 : body.data) === null || _d === void 0 ? void 0 : _d.error) === null || _e === void 0 ? void 0 : _e.message;
                    }
                })];
        });
    });
}
export function del(endPoint, data) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, superagent
                    .delete(BASE_URL + endPoint)
                    .set({ 'Authorization': 'Bearer ' + getAuthToken(), 'Language': 'nl', 'Content-Type': 'application/json' })
                    .send(data)
                    .accept('application/json')
                    .then(function (result) {
                    /*
                    if (result.body.data && result.body.data.error) {
                        if (result.body.data.error.code === 401) {
                            removeCookie(ACCESS_TOKEN);
                            (window as any).location = window.location.pathname;
                        } else {
                            throw result.body.data.error.message;
                        }
                    }
                    */
                    return result.body.data;
                }, function (error) {
                    var _a, _b, _c, _d, _e;
                    var body = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.body;
                    if (((_c = (_b = body === null || body === void 0 ? void 0 : body.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.code) === 401) {
                        removeCookie(ACCESS_TOKEN);
                        window.location = window.location.pathname;
                    }
                    else {
                        throw (_e = (_d = body === null || body === void 0 ? void 0 : body.data) === null || _d === void 0 ? void 0 : _d.error) === null || _e === void 0 ? void 0 : _e.message;
                    }
                })];
        });
    });
}
